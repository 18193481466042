import { Injectable } from '@angular/core'

import {
  MatDialog,
  MatDialogRef,
  MatDialogConfig,
} from '@angular/material/dialog'

import { ErrorHandlingComponent } from '../dialog-modals/error-handling/error-handling.component'

@Injectable({
  providedIn: 'root',
})
export class DialogModalsService {
  dialogConfig: MatDialogConfig = {
    panelClass: ['dialog-container', 'tql-style'],
  }

  dialogRef?: MatDialogRef<ErrorHandlingComponent>

  dialogState: unknown

  constructor(private dialog: MatDialog) {}

  openErrorMessage(dialogConfig?: MatDialogConfig) {
    this.dialogRef = this.dialog.open(ErrorHandlingComponent, {
      ...this.dialogConfig,
      ...dialogConfig,
    })

    this.dialogRef.afterClosed().subscribe((response: unknown) => {
      this.reset()
    })

    return this.dialogRef
  }

  open(dialogConfig?: MatDialogConfig) {
    this.openErrorMessage(dialogConfig)
  }

  close() {
    this.dialogRef?.close()
  }

  reset(): void {
    this.dialogRef = undefined
  }
}
