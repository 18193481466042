import { Component } from '@angular/core'
import { environment } from 'src/environments/environment'

@Component({
  selector: 'tql-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
  
export class HeaderComponent {
  feedbackEmailReceptionList: string[] = [];
  stringConversion: string = '';
  mailString: String = '';

  ngOnInit() {
    this.feedbackEmailReceptionList = environment.feedbackEmailReceptionList;
    this.stringConversion = this.feedbackEmailReceptionList.join(',').replace(',', ";").toString();
    this.mailString = "mailto:" + this.stringConversion.split(",", 2);
  }
}
