<div class="autocomplete-wrapper" [formGroup]="parentFormGroup" [ngClass]="{ 'tql-style': useTqlStyling }"
  [ngStyle]="{ width: getWidth(inputWidthCategory) }">
  <mat-form-field [attr.id]="formFieldId" appearance="fill">
    <label *ngIf="labelText && useTqlStyling">{{ labelText }} <span *ngIf="isRequired" class="asterisk"> *</span>
      <!-- <tql-mat-info-tooltip-modal
        *ngIf="tooltipText.length > 0"
        [name]="tooltipName"
        [content]="tooltipText"
        [placement]="tooltipPlacement"
        [isClickOpen]="isTooltipClickOpen"
        [isModalInXs]="isTooltipModalInXs"
        (click)="$event.stopPropagation(); $event.target.blur()"
      >
      </tql-mat-info-tooltip-modal> -->
    </label>
    <label *ngIf="disableAddressAutofill" class="filler-label">filler-label</label>
    <input tab-directive #trigger="matAutocompleteTrigger" matInput type="text" [placeholder]="placeholderText"
      [formControlName]="autocompleteFormControlName" [matAutocomplete]="auto" [matAutocompleteDisabled]="isDisabled"
      [autocomplete]="disableAutofill ? 'off' : 'on'" [ngClass]="{
        'is-invalid': showErrors && autocompleteAbstractControl.invalid
      }" class="tooltip-touch-action" [tabindex]="tabIndex" [attr.testing-id]="testingId"
      [matTooltip]="showTooltipOnSelected && autocompleteAbstractControl.value ? selectedOptionTooltipText : ''" />
    <button *ngIf="includeClearButton && autocompleteAbstractControl.value" matSuffix mat-button mat-icon-button
      type="button" (click)="clearInput()" [tabindex]="tabIndex">
      <mat-icon>close</mat-icon>
    </button>
    <mat-autocomplete autoActiveFirstOption="true" #auto="matAutocomplete" [displayWith]="displayFn"
      [panelWidth]="panelWidth" [class]="useTqlStyling ? 'tql-style' : ''" (optionSelected)="onOptionSelected($event)">
      <mat-option *ngFor="let option of optionsData | async" [value]="option.value" class="tooltip-touch-action"><!-- TODO REVISIT MAT-TOOLTIP for <mat-option ... [matTooltip]="
          showTooltipOnOptions && option[optionTooltipTogglePropertyName]
            ? option[optionTooltipDisplayPropertyName] || option.displayText
            : null
        "-->
        {{ option.displayText }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>