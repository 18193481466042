import {
  ActivatedRouteSnapshot,
  ResolveFn,
  RouterStateSnapshot,
} from '@angular/router'
import { PreQuote, Quote, QuoteSource } from '../shared/models/quote'
import { EMPTY, Observable, mergeMap, of, take } from 'rxjs'
import { QuoteService } from '../shared/services/quote.service'
import { inject } from '@angular/core'
import { StepsInUserJourney } from '../app-routing.module'
import { AppStateService } from '../shared/services/app-state.service'

export const QuoteResolver: ResolveFn<Quote | PreQuote> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
): Observable<Quote | PreQuote> => {
  const quoteService = inject(QuoteService)
  const appStateService = inject(AppStateService)

  let targetRoute = state.url.split('?')[0]
  switch (targetRoute.substring(1)) {
    case StepsInUserJourney.SelectRate:
      return quoteService
        .getQuote(QuoteSource.QUICKQUOTE, route.queryParams['quoteId'])
        .pipe(
          take(1),
          mergeMap((quote) => {
            if (quote) {
              appStateService.setQuote(quote)
              return of(quote)
            } else {
              return EMPTY
            }
          }),
        )
    default:
      return EMPTY
  }
}
