import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable, of, tap } from 'rxjs'
import { PreQuote, Quote, QuoteCreateCM } from '../models/quote'
import { environment } from 'src/environments/environment'
import { QuoteSource } from '../models/quote'

@Injectable({
  providedIn: 'root',
})
export class QuoteService {
  apiUrl: string = ''
  createdWithId$: BehaviorSubject<string> = new BehaviorSubject<string>('')
  constructor(private http: HttpClient) {
    this.apiUrl = `${environment.quickQuoteApiServerUrl}/create-quote`
  }
  createQuote(quote: QuoteCreateCM, prequoteId?: string): Observable<any> {
    quote.prequoteId = prequoteId
    try {
      var zonedOut = `${(quote.stops[0]
        .scheduledDate! as Date).getFullYear()}-${
        (quote.stops[0].scheduledDate! as Date).getMonth() + 1
      }-${(quote.stops[0].scheduledDate! as Date).getDate()}`
      quote.stops[0].scheduledDate = zonedOut
    } catch (e) {}
    if (!environment.isProduction && !environment.isStage) {
      console.log('sending ', quote, 'to ', this.apiUrl)
    }

    return this.http
      .post<Response>(`${this.apiUrl}/`, quote, {
        headers: new HttpHeaders({
          'Access-Control-Allow-Headers': 'location',
          'Access-Control-Allow-Origin': '*',
        }),
        observe: 'response',
      })
      .pipe(
        tap((response) =>
          this.createdWithId$.next(
            this._getQuoteIdFromResponseHeader(response),
          ),
        ),
      )
  }

  getQuote(
    quoteType: keyof typeof QuoteSource,
    id: string,
  ): Observable<Quote | PreQuote> {
    switch (quoteType) {
      case QuoteSource.PREQUOTE:
        if (!environment.isProduction && !environment.isStage) {
          console.log('Fetching Prequote ', id)
        }
        return this.http.get<PreQuote>(`${this.apiUrl}/prequote/${id}`)
      default:
        return this.http.get<Quote>(`${this.apiUrl}/${id}`)
    }
  }

  private _getQuoteIdFromResponseHeader(response: any): string {
    let quoteId = ''
    let resultUrl = response.headers.get('location')
    let quoteIdZone = resultUrl.split('/').slice(-1)
    console.log(quoteIdZone)
    if (quoteIdZone.length === 1) {
      return quoteIdZone[0]
    }
    return quoteId
  }
}
