import {
  Component,
  EventEmitter,
  OnInit,
  Input,
  ViewChild,
  AfterViewInit,
  OnDestroy,
  ChangeDetectionStrategy,
  Output,
} from '@angular/core'
import { UntypedFormGroup, AbstractControl } from '@angular/forms'
import { Observable, Subscription } from 'rxjs'

import { MatSelect } from '@angular/material/select'

import {
  CdkConnectedOverlay,
  PositionStrategy,
  GlobalPositionStrategy,
} from '@angular/cdk/overlay'

@Component({
  selector: 'tql-form-ele-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectComponent implements OnInit, AfterViewInit, OnDestroy {
  /* tslint:disable */
  @ViewChild(MatSelect) matSelect: MatSelect | undefined

  // @Output() optionCleared = new EventEmitter<void>();

  @Input() formFieldId: string | undefined
  @Input() tabIndex: number | undefined
  @Input() labelText: string = ''
  @Input() placeholderText: string = ''
  @Input() selectionData!: Observable<any>
  @Input() displayPropertyName: string = 'displayText'
  @Input() valuePropertyName!: string
  @Input() isMultiSelect: boolean = false
  @Input() allowSelectAll: boolean = false
  @Input() offerBlankOption: boolean = false
  @Input() blankOptionDisplayText: string = ''
  @Input() disableOptionCentering: boolean = true
  @Input() isRequired: boolean = false
  @Input() showErrors: boolean = false
  @Input() tooltipName: string = ''
  @Input() tooltipText: string = ''
  @Input() tooltipPlacement:
    | 'top'
    | 'top-start'
    | 'top-end'
    | 'right'
    | 'right-start'
    | 'right-end'
    | 'bottom'
    | 'bottom-start'
    | 'bottom-end'
    | 'left'
    | 'left-start'
    | 'left-end'
    | 'auto'
    | 'auto-start'
    | 'auto-end' = 'right'
  @Input() isTooltipClickOpen: boolean = false
  @Input() isTooltipModalInXs: boolean = false
  @Input() showTooltipOnSelected: boolean = false
  @Input() showTooltipOnOptions: boolean = false
  @Input() optionTooltipTogglePropertyName: string = 'showTooltip'
  @Input() optionTooltipDisplayPropertyName: string = 'tooltipText'
  @Input() requiredErrorMessage: string = 'Required'
  @Input() useTqlStyling: boolean = true
  @Input() handleAddRemoveControl: boolean = true
  @Input() includeClearButton: boolean = false

  @Input() parentFormGroup!: UntypedFormGroup
  @Input() selectFormControlName!: string
  @Input() selectAbstractControl!: AbstractControl

  @Input() inputWidthCategory: string = 'md' // xs, sm, md, lg // req

  @Input() testingId: string = ''

  isActive: boolean = false
  selections: any[] = []

  selectedOptionsTooltipText: string = ''

  private _selectionDataSubscription: Subscription = Subscription.EMPTY

  constructor() {}

  ngOnInit() {
    this._setUpForm()

    this._selectionDataSubscription = this.selectionData.subscribe(
      (selectionData) => (this.selections = selectionData),
    )
  }

  ngAfterViewInit() {
    // if (this.useTqlStyling) {
    //   let selectionOverlay = this.matSelect.overlayDir as CdkConnectedOverlay;
    //   selectionOverlay.positions = [
    //     {
    //       offsetX: 1,
    //       offsetY: 0,
    //       originX: 'start',
    //       originY: 'bottom',
    //       overlayX: 'start',
    //       overlayY: 'top'
    //     }
    //   ];
    // }
  }

  ngOnDestroy() {
    this._removeControl()

    if (this._selectionDataSubscription) {
      this._selectionDataSubscription.unsubscribe()
    }
  }

  clearInput() {
    this.selectAbstractControl.patchValue('')
    this.selectAbstractControl.updateValueAndValidity()
    // this.optionCleared.emit();
  }

  getWidth(widthCategory: any) {
    switch (widthCategory) {
      case 'xs':
        return '88px'
      case 'sm':
        return '176px'
      case 'md':
        return '256px'
      case 'lg':
        return '344px'
      case 'half-containment':
        return '46%'
      case 'third-containment':
        return '30%'
      case 'three-quarters-containment':
        return '68%'
      default:
        return '100%'
    }
  }

  toggleSingleOption() {
    if (this.showTooltipOnSelected && this.selectAbstractControl.value) {
      this.selectedOptionsTooltipText = this.selectAbstractControl.value
        .map((selected: any) => selected[this.displayPropertyName])
        .filter((val: any) => val)
        .join(', ')
    }
  }

  private _setUpForm() {
    if (this.handleAddRemoveControl) {
      this.parentFormGroup.addControl(
        this.selectFormControlName,
        this.selectAbstractControl,
      )
    }
  }

  private _removeControl() {
    if (this.handleAddRemoveControl) {
      this.parentFormGroup.removeControl(this.selectFormControlName)
    }
  }
}
