import { Component, OnInit, Input, OnDestroy } from '@angular/core'
import { UntypedFormGroup, AbstractControl } from '@angular/forms'

@Component({
  selector: 'tql-form-ele-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
})
export class CheckboxComponent implements OnInit, OnDestroy {
  @Input() checkboxLabel: string = 'Option 1'
  @Input() extraLabel: string = 'Yes'
  @Input() useExtraLabel: boolean = false
  @Input() useTqlStyling: boolean = true
  @Input() colorTheme: string = 'primary'
  @Input() handleAddRemoveControl: boolean = true
  @Input() isRequired: boolean = false
  @Input() showErrors: boolean = true
  @Input() tabIndex: number = -1
  @Input() idxData: number = 0
  @Input() parentFormGroup!: UntypedFormGroup
  @Input() checkboxFormControlName!: string
  @Input() checkboxAbstractControl!: AbstractControl | null

  @Input() testingId: string = ''

  constructor() {}

  ngOnInit(): void {
    this._setupForm()
  }

  ngOnDestroy() {
    this._removeControl()
  }

  private _setupForm() {
    if (this.handleAddRemoveControl) {
      this.parentFormGroup.addControl(
        this.checkboxFormControlName,
        this.checkboxAbstractControl,
      )
    }
  }

  private _removeControl() {
    if (this.handleAddRemoveControl) {
      this.parentFormGroup.removeControl(this.checkboxFormControlName)
    }
  }
}
