import {
  ActivatedRouteSnapshot,
  ResolveFn,
  RouterStateSnapshot,
} from '@angular/router'
import {
  EMPTY,
  Observable,
  Subject,
  merge,
  mergeMap,
  of,
  take,
  takeUntil,
} from 'rxjs'
import { inject } from '@angular/core'
import { AppStateService } from '../shared/services/app-state.service'
import { RateService } from '../shared/services/rate.service'
import { Rate, RateServiceResponsePayload } from '../shared/models/rate'
import { StepsInUserJourney } from '../app-routing.module'

export const RateResolver: ResolveFn<RateServiceResponsePayload> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
): Observable<RateServiceResponsePayload> => {
  const _unsubscribe$: Subject<void> = new Subject<void>()
  let localAppState: any
  const appStateService = inject(AppStateService)
  const rateService = inject(RateService)
  let rates: Rate[] = []
  let targetRoute = state.url.split('?')[0]
  switch (targetRoute.substring(1)) {
    case StepsInUserJourney.SelectRate:
      return rateService.getRates(route.queryParams['quoteId']).pipe(
        take(1),
        mergeMap((rates) => {
          if (rates) {
            appStateService.setRates(rates)
            return of(rates)
          } else {
            return EMPTY
          }
        }),
      )
    default:
      return EMPTY
  }
}
