<div class="select-wrapper" [formGroup]="parentFormGroup" [ngClass]="{ 'tql-style': useTqlStyling }">
  <mat-form-field [attr.id]="formFieldId" [ngStyle]="{
    width: getWidth(inputWidthCategory)
  }">
    <label *ngIf="labelText && useTqlStyling">{{ labelText }} <span *ngIf="isRequired" class="asterisk"> *</span>

    </label>
    <mat-label *ngIf="!useTqlStyling">{{ labelText }}</mat-label>
    <mat-select color="primary" [formControlName]="selectFormControlName" [ngClass]="{
        'is-active': isActive,
        'is-invalid': showErrors && selectAbstractControl.invalid
      }" class="tooltip-touch-action" (openedChange)="isActive = $event!=null" overlayDir>
      <mat-option *ngIf="!isMultiSelect && offerBlankOption" [value]="null">{{
        blankOptionDisplayText
        }}</mat-option>
      <mat-option *ngFor="let selection of selectionData | async" color="primary"
        [value]="valuePropertyName ? selection[valuePropertyName] : selection" [matTooltip]="
          showTooltipOnOptions && selection[optionTooltipTogglePropertyName]
            ? selection[optionTooltipDisplayPropertyName] ||
              selection[displayPropertyName]
            : null
        " class="tooltip-touch-action" (click)="toggleSingleOption()">
        {{ selection[displayPropertyName] }}
      </mat-option>
    </mat-select>
    <button *ngIf="includeClearButton && selectAbstractControl.value" matSuffix mat-icon-button type="button"
      (click)="clearInput()" [tabindex]="tabIndex">
      <span>X</span>
    </button>
    <mat-hint *ngIf="
        useTqlStyling &&
        showErrors &&
        selectAbstractControl.hasError('required')
      ">
      {{ requiredErrorMessage }}
    </mat-hint>
  </mat-form-field>
</div>