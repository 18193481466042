<div class="header">
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 327 96.8" xml:space="preserve" class="logo-area">
    <style type="text/css">
      .st0 {
        fill: #fff;
      }

      .st1 {
        fill: #fff;
      }

      .st2 {
        fill: #fff;
      }
    </style>
    <path class="st0" d="M25.2,27H0V14h64.3v12.9H39.2v66.3H25.1C25.1,93.2,25.2,27,25.2,27z M137.1,95.6l-9.5-8.4
        c-6.7,4.7-14.8,7.5-24.1,7.5c-24.3,0-41.3-18.4-41.3-40.7v-0.2c0-22.3,17.2-40.9,41.6-40.9S145,31.2,145,53.5v0.2
        c0,9-2.9,17.4-8,24.3l9.1,7.7L137.1,95.6z M117.4,78.1l-13.7-11.7l9-10.2l14,12.7c2.5-4.3,3.9-9.4,3.9-15v-0.2
        c0-15.4-11.3-28.1-26.9-28.1S76.8,38,76.8,53.4v0.2c0,15.4,11.3,28,26.9,28C108.9,81.8,113.5,80.4,117.4,78.1z M157.4,14h14v66.6
        H213v12.7h-55.5L157.4,14L157.4,14z" />
    <g>
      <path class="st1" d="M283.6,51.7c14.5-6.6,26-18.1,32.7-32.4C305.2,5.7,286.9-1.2,268.6,3.1c-23.8,5.6-39.2,28.4-36.3,52.1
          C250.3,60.2,268.6,58.6,283.6,51.7z M325.7,38.4c-1.1-4.3-2.6-8.4-4.7-12.1c-2.8,15.2-9.7,28.7-19.8,39.1
          c-12.7,13.2-30.4,21.3-49.8,22.3c10.8,7.9,24.9,11.3,39,7.9C315.9,89.4,331.7,63.9,325.7,38.4L325.7,38.4z" />
      <path class="st2" d="M225.6,65.2c-0.2-0.2-0.3-0.5-0.5-0.8C225.3,64.7,225.5,65,225.6,65.2z M227,67.5c-0.1-0.2-0.3-0.4-0.4-0.6
          C226.8,67,226.9,67.3,227,67.5z" />
      <path class="st1" d="M292.3,58.7c17.6-12.3,28.9-32.8,29.4-56c-1.2,22.8-13.2,42.9-31.3,54.5c-18,11.7-42.2,15-65.6,6.7
          c0.1,0.1,0.2,0.3,0.2,0.4c0.2,0.2,0.3,0.5,0.5,0.8l1,1.6c0.1,0.2,0.3,0.4,0.4,0.6c0.1,0.2,0.2,0.4,0.3,0.5
          C251,75.4,274.8,71.2,292.3,58.7z" />
    </g>
  </svg>

  <div class="header-bar"></div>

  <div class="app-title">
    <h1><i>QuickQuote</i></h1>
  </div>


  <div class="feedback-trigger-container">

    <div class="icon-container">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0 24V2.66667C0 1.19391 1.19391 0 2.66667 0H21.3333C22.8061 0 24 1.19391 24 2.66667V16C24 17.4728 22.8061 18.6667 21.3333 18.6667H8C7.42284 18.6656 6.86109 18.8529 6.4 19.2L0 24ZM2.66667 2.66667V18.6667L5.512 16.5333C5.97286 16.1857 6.53476 15.9984 7.112 16H21.3333V2.66667H2.66667Z"
          fill="white" />
        <path
          d="M6.66667 13.3333V10.9067L13.5467 4.14737C13.7467 3.95088 14.0567 3.95088 14.2567 4.14737L16.0267 5.88632C16.2267 6.08281 16.2267 6.38737 16.0267 6.58386L9.13667 13.3333H6.66667ZM17.6667 13.3333H11.1667L13.1667 11.3684H17.6667C18.2167 11.3684 18.6667 11.8105 18.6667 12.3509C18.6667 12.8912 18.2167 13.3333 17.6667 13.3333Z"
          fill="white" />
      </svg>

    </div>
    <div class="feedback-trigger-prompt">
      <h3><a href="{{ mailString }}">Feedback</a></h3>
    </div>
  </div>

</div>
<tql-breadcrumb></tql-breadcrumb>