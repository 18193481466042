import { LogLevel } from '@azure/msal-browser'

export const environment = {
  isProduction: false,
  isStage: true,
  logApiCalls: false,
  quickQuoteBffApiHealthCheck:
    'https://lmservicesextstage.tql.com/quickquote/healthcheck',
  quickQuoteApiServerUrl: 'https://lmservicesextstage.tql.com/quickquote',
  feedbackEmailReceptionList: ['ndupont@tql.com','cdean@tql.com'],
  msalConfigAD: {
    tenantId: 'c4dde173-b56e-463d-a6e0-0983142ad7ed',
    clientId: 'da9c07ad-d78b-4981-a369-456a30d4760d',
    postLogoutRedirectUri: 'https://quickquotestage.tql.com',
    navigateToLoginRequestUrl: true,
    authority:
      'https://login.microsoftonline.com/c4dde173-b56e-463d-a6e0-0983142ad7ed',
    knownAuthorities: [
      'https://login.microsoftonline.com/c4dde173-b56e-463d-a6e0-0983142ad7ed',
    ],
    redirectUri: 'https://quickquotestage.tql.com',
    scopes: [
      'https://shiptql.onmicrosoft.com/salesops.pricing.client.confidential.SpotPricing.QuickQuote.BFF.stage/employee',
    ],
    loggingEnabled: true,
    logLevel: LogLevel.Verbose,
  },
}
