import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

  // A BehaviourSubject is an Observable with a default value
  public isLoading = new BehaviorSubject<boolean>(false);  

  constructor() { }
}
