import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _isAuthenticated$ = new BehaviorSubject<boolean>(false)
  isCustomer$ = new BehaviorSubject<boolean>(false)
  isEmployee$ = new BehaviorSubject<boolean>(true)

  constructor() {}

  get isAuthenticated(): Observable<boolean> {
    return this._isAuthenticated$.asObservable()
  }

  public setIsAuthenticated(authenticated: boolean) {
    this._isAuthenticated$.next(authenticated)
  }

  getCompanyId(): number | null {
    let companyId = localStorage.getItem('companyId') || null
    if (companyId === undefined || companyId === null || companyId === '') {
      return null
    } else {
      return parseInt(companyId)
    }
  }

  getAccountType(): number | null {
    let accountType = localStorage.getItem('accountType') || null
    if (
      accountType === undefined ||
      accountType === null ||
      accountType === ''
    ) {
      return null
    } else {
      return parseInt(accountType)
    }
  }
}
