import { BehaviorSubject } from 'rxjs'
import { ICustomer } from './customer-option.interface'
import { PreQuote, Quote } from './quote'

export class ApplicationState {
  CUSTOMER: BehaviorSubject<ICustomer> = new BehaviorSubject<any>(null)
  QUOTE: BehaviorSubject<Quote | PreQuote> = new BehaviorSubject<any>(null)
  RATES: BehaviorSubject<any> = new BehaviorSubject<any>(null)
  STOPS: BehaviorSubject<Location[]> = new BehaviorSubject<any>(null)
  SHIPMENT_DETAILS: BehaviorSubject<any> = new BehaviorSubject<any>(null)
  TRAYSTATUS: BehaviorSubject<any> = new BehaviorSubject<any>(null)

  constructor() {
    this.CUSTOMER.next({
      id: 1,
      name: 'Test Customer',
    } as ICustomer)

    this.STOPS.next([{}, {}] as Location[])

    this.TRAYSTATUS.next(true)
  }

  get CUSTOMER$() {
    return this.CUSTOMER.value
  }

  set CUSTOMER$(customer: ICustomer) {
    this.CUSTOMER.next(customer)
  }

  get QUOTE$() {
    return this.QUOTE.value
  }

  set QUOTE$(quote: Quote | PreQuote) {
    this.QUOTE.next(quote)
  }

  get RATES$() {
    return this.RATES.value
  }

  set RATES$(rates: any) {
    this.RATES.next(rates)
  }

  get STOPS$() {
    return this.STOPS.value
  }

  set STOPS$(stops: Location[]) {
    this.STOPS.next(stops)
  }

  get SHIPMENT_DETAILS$() {
    return this.SHIPMENT_DETAILS.value
  }

  set SHIPMENT_DETAILS$(shipmentDetails: any) {
    this.SHIPMENT_DETAILS.next(shipmentDetails)
  }

  get TRAYSTATUS$() {
    return this.TRAYSTATUS.value
  }

  set TRAYSTATUS$(trayStatus: any) {
    this.TRAYSTATUS.next(trayStatus)
  }
}
