import { Component, OnInit, Input, OnDestroy } from '@angular/core'
import { UntypedFormGroup, AbstractControl, FormGroup } from '@angular/forms'
import { Subscription } from 'rxjs'

@Component({
  selector: 'tql-form-ele-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputComponent implements OnInit, OnDestroy {
  @Input() formFieldId!: string
  @Input() inputType: string = 'text'
  @Input() customInputType?:
    | 'naturalNumber'
    | 'wholeNumber'
    | 'integer'
    | 'monetary'
    | 'positiveDecimal'
    | 'phoneNumber'
    | 'negativeMonetary'
  @Input() symbol?: 'dollar' | 'percent'
  @Input() labelText: string = ''
  @Input() placeholderText: string = ''
  @Input() isReadOnly: boolean = false
  @Input() isRequired: boolean = false
  @Input() showErrors: boolean = true
  @Input() tooltipName: string = ''
  @Input() tooltipText: string = ''
  @Input() tooltipPlacement:
    | 'top'
    | 'top-start'
    | 'top-end'
    | 'right'
    | 'right-start'
    | 'right-end'
    | 'bottom'
    | 'bottom-start'
    | 'bottom-end'
    | 'left'
    | 'left-start'
    | 'left-end'
    | 'auto'
    | 'auto-start'
    | 'auto-end' = 'right'
  @Input() isTooltipClickOpen: boolean = false
  @Input() isTooltipModalInXs: boolean = false
  @Input() maxLength: number = 256
  @Input() minValue: number = -Infinity
  @Input() maxValue: number = Infinity
  @Input() includeClearButton: boolean = false
  @Input() applyCommasToNumber: boolean = false
  @Input() prohibitedCharactersRegex?: string
  @Input() decimalPlacesAllowed?: number
  @Input() requiredErrorMessage: string = 'Required'
  @Input() minValueErrorMessage: string = 'Min error'
  @Input() maxValueErrorMessage: string = 'Max error'
  @Input() minLengthErrorMessage: string = 'Min length error'
  @Input() maxLengthErrorMessage: string = 'Max length error'
  @Input() emailErrorMessage: string = 'Invalid email format'
  @Input() patternErrorMessage: string = 'Invalid pattern'
  @Input() showTooltipOnInput: boolean = false
  @Input() tooltipTextOnInput: string = ''
  @Input() showSingleCustomValidation: boolean = true
  @Input() useTqlStyling: boolean = true
  @Input() handleAddRemoveControl: boolean = true
  @Input() excludePeriodsFromMaxLengthCount: boolean = false
  @Input() excludeCommasFromMaxLengthCount: boolean = false
  @Input() excludeDashesFromMaxLengthCount: boolean = false
  @Input() disableAutofill: boolean = true
  @Input() disableAddressAutofill: boolean = false
  @Input() tabIndex?: number
  @Input() inputWidthCategory?: string // xs, sm, md, lg // optional
  @Input() parentFormGroup!: FormGroup
  @Input() inputFormControlName!: string
  @Input() inputAbstractControl!: AbstractControl

  @Input() testingId?: string

  objectKeys = Object.keys
  highestPriorityCustomErrorMessage: string = ''

  private _prohibitedCharactersRegex?: RegExp

  private _errorsSubscription: Subscription = Subscription.EMPTY

  constructor() {}

  ngOnInit() {
    this._setUpForm()
    this._setHighestPriorityCustomErrorMessage()
    this._handleStatusChange()
    if (this.prohibitedCharactersRegex) {
      this._prohibitedCharactersRegex = new RegExp(
        this.prohibitedCharactersRegex,
      )
    }
    // if the value is pre-populated from an existing submission (a quote, for example), then we need to update the form controls
    this.checkInput(null)
  }

  ngOnDestroy() {
    this._removeControl()

    if (this._errorsSubscription) {
      this._errorsSubscription.unsubscribe()
    }
  }

  getWidth(widthCategory: any) {
    switch (widthCategory) {
      case 'xxs':
        return '88px'
      case 'xs':
        return '124px'
      case 'sm':
        return '176px'
      case 'md':
        return '256px'
      case 'lg':
        return '344px'
      case 'half-containment':
        return '46%'
      case 'third-containment':
        return '30%'
      case 'three-quarters-containment':
        return '68%'
      default:
        return '100%'
    }
  }

  maxLengthToApply = () => {
    let lengthToAdd = 0
    if (this.inputAbstractControl && this.inputAbstractControl.value) {
      if (this.excludePeriodsFromMaxLengthCount) {
        lengthToAdd =
          lengthToAdd +
          (this.inputAbstractControl.value.toString().match(/\./g) || []).length
      }
      if (this.excludeCommasFromMaxLengthCount) {
        lengthToAdd =
          lengthToAdd +
          (this.inputAbstractControl.value.toString().match(/,/g) || []).length
      }
      if (this.excludeDashesFromMaxLengthCount) {
        lengthToAdd =
          lengthToAdd +
          (this.inputAbstractControl.value.toString().match(/-/g) || []).length
      }
    }
    if (this.maxLength !== null && this.maxLength >= 0 && lengthToAdd) {
      return lengthToAdd + parseInt(this.maxLength.toString())
    } else {
      return this.maxLength
    }
  }

  checkInput(event: any | null) {
    if (event !== null) {
      if (this.inputType === 'text') {
        if (this.customInputType === 'naturalNumber') {
          // {1, 2, 3, ...}
          const pattern = /^[1-9]\d*$/
          if (event.target.value && !pattern.test(event.target.value)) {
            event.target.value = event.target.value.replace(/(^0|[^0-9])/g, '') // remove leading 0 and any character not a digit 0-9
            this.inputAbstractControl.patchValue(event.target.value)
            this.inputAbstractControl.updateValueAndValidity()
          }
        } else if (this.customInputType === 'wholeNumber') {
          // {0, 1, 2, 3, ...}
          const pattern = /^(0|[1-9]\d*)$/
          if (event.target.value && !pattern.test(event.target.value)) {
            event.target.value = event.target.value.replace(/[^0-9]/g, '') // remove any character not a digit 0-9
            event.target.value = event.target.value.replace(/^0[0-9]*$/, '0') // input starting with 0 cannot be followed by another number
            this.inputAbstractControl.patchValue(event.target.value)
            this.inputAbstractControl.updateValueAndValidity()
          }
        } else if (this.customInputType === 'integer') {
          // {-3, -2, -1, 0, 1, 3}
          const pattern = /^-?[1-9]\d*$/
          if (event.target.value && !pattern.test(event.target.value)) {
            const onlyDash = /^-$/
            if (!onlyDash.test(event.target.value)) {
              event.target.value = event.target.value.replace(/[^-0-9]/g, '') // remove any character not a dash or digit 0-9
              event.target.value = event.target.value.replace(/^-0$/, '-') // remove 0 if it follows a leading dash

              // remove any secondary dashes
              let match = event.target.value.match(/^-?[1-9]*(?=-)/)
              if (match) {
                event.target.value = match[0]
              }

              this.inputAbstractControl.patchValue(event.target.value)
              this.inputAbstractControl.updateValueAndValidity()
            }
          }
        } else if (this.customInputType === 'monetary') {
          const pattern = /^(0|0\.[0-9]{2}|[1-9][0-9]*|[1-9][0-9]*\.[0-9]{2})$/
          if (event.target.value && !pattern.test(event.target.value)) {
            event.target.value = event.target.value.replace(/[^.0-9]/g, '') // remove any character not a digit 0-9 or '.'
            event.target.value = event.target.value.replace(/^0[0-9].*$/g, '0') // input starting with 0 cannot be followed by another number

            // input can only have 1 decimal and 2 numbers following the decimal
            let subStrs = event.target.value.split('.')
            if (subStrs.length === 2) {
              let match = event.target.value.match(/^[0-9]*\.[0-9]{0,2}(?=)/)
              if (match) {
                event.target.value = match[0]
              }
            } else if (subStrs.length > 2) {
              let newStr = ''
              subStrs.forEach(function (value: string, i: number) {
                if (i === 0) {
                  newStr = value + '.'
                  return
                }
                newStr += value
              })
              event.target.value = newStr
            }
            this.inputAbstractControl.patchValue(event.target.value)
            this.inputAbstractControl.updateValueAndValidity()
          }
        } else if (this.customInputType === 'negativeMonetary') {
          const pattern = /^(0|-{0,1}0\.[0-9]{0,2}|-{0,1}[1-9][0-9]*|-{0,1}[1-9][0-9]*\.[0-9]{0,2})$/
          if (event.target.value && !pattern.test(event.target.value)) {
            event.target.value = event.target.value.replace(/[^-.0-9]/g, '') // remove any character not a digit 0-9, '.', or '-'
            event.target.value = event.target.value.replace(/^0[0-9].*$/g, '0') // input starting with 0 cannot be followed by another number
            event.target.value = event.target.value.replace(/^-0$/, '-') // remove 0 if it follows a leading dash

            // input can only have 1 decimal and 2 numbers following the decimal
            let subStrs = event.target.value.split('.')
            if (subStrs.length === 2) {
              let match = event.target.value.match(
                /^-{0,1}[0-9]*\.[0-9]{0,2}(?=)/,
              )
              if (match) {
                event.target.value = match[0]
              }
            } else if (subStrs.length > 2) {
              let newStr = ''
              subStrs.forEach(function (value: string, i: number) {
                if (i === 0) {
                  newStr = value + '.'
                  return
                }
                newStr += value
              })
              event.target.value = newStr
            }

            // remove any secondary dashes
            let match = event.target.value.match(/^-?[1-9]*(?=-)/)
            if (match && match[0]) {
              event.target.value = match[0]
            }

            this.inputAbstractControl.patchValue(event.target.value)
            this.inputAbstractControl.updateValueAndValidity()
          }
        } else if (this.customInputType === 'positiveDecimal') {
          let regexPattern = `^(0|0\.[0-9]+|[1-9][0-9]*|[1-9][0-9]*\.[0-9]+)$`
          let matchRegexPattern = `^[0-9]*\.[0-9]+(?=)`
          if (this.decimalPlacesAllowed && this.decimalPlacesAllowed > 0) {
            regexPattern = `^(0|0\.[0-9]{1,${this.decimalPlacesAllowed}}|[1-9][0-9]*|[1-9][0-9]*\.[0-9]{1,${this.decimalPlacesAllowed}})$`
            matchRegexPattern = `^[0-9]*\.[0-9]{0,${this.decimalPlacesAllowed}}(?=)`
          }
          const pattern = new RegExp(regexPattern)
          const matchPattern = new RegExp(matchRegexPattern)
          if (event.target.value && !pattern.test(event.target.value)) {
            event.target.value = event.target.value.replace(/[^.0-9]/g, '') // remove any character not a digit 0-9 or '.'
            event.target.value = event.target.value.replace(/^0[0-9].*$/g, '0') // input starting with 0 cannot be followed by another number

            // input can only have 1 decimal
            let subStrs = event.target.value.split('.')
            if (subStrs.length === 2) {
              let match = event.target.value.match(matchPattern)
              if (match) {
                event.target.value = match[0]
              }
            } else if (subStrs.length > 2) {
              let newStr = ''
              subStrs.forEach(function (value: string, i: number) {
                if (i === 0) {
                  newStr = value + '.'
                  return
                }
                newStr += value
              })
              event.target.value = newStr
            }
            this.inputAbstractControl.patchValue(event.target.value)
            this.inputAbstractControl.updateValueAndValidity()
          }
        } else if (this.customInputType === 'phoneNumber') {
          // {0, 1, 2, 3, ...}
          const pattern = /(^[0-9]{0,10})$|(^\([0-9]{0,10}$)|(^\([0-9]{3}\)\s{0,1}[0-9]{0,7}$)|(^\([0-9]{3}\)\s{0,1}[0-9]{3}\-[0-9]{0,4}$)/
          if (event.target.value && !pattern.test(event.target.value)) {
            if (event.target.value.charAt(0) === '(') {
              let subStrings = event.target.value.split('(')
              let correctedString = subStrings
                .map(
                  (subStr: string) => (subStr = subStr.replace(/[^0-9]/g, '')),
                )
                .join('')
              correctedString = correctedString.substring(0, 10)
              correctedString = this._convertTenDigitsToPhoneNumberFormat(
                correctedString,
              )
              event.target.value =
                correctedString.charAt(0) === '('
                  ? correctedString
                  : '(' + correctedString
            } else {
              event.target.value = event.target.value.replace(/[^0-9]/g, '') // remove any character not a digit 0-9
              event.target.value = event.target.value.substring(0, 10)
              event.target.value = this._convertTenDigitsToPhoneNumberFormat(
                event.target.value,
              )
            }
            this.inputAbstractControl.patchValue(event.target.value)
            this.inputAbstractControl.updateValueAndValidity()
          } else {
            if (
              event.target.value &&
              event.inputType != 'deleteContentBackward'
            ) {
              event.target.value = this._convertTenDigitsToPhoneNumberFormat(
                event.target.value,
              )
              this.inputAbstractControl.patchValue(event.target.value)
              this.inputAbstractControl.updateValueAndValidity()
            }
          }
        }

        if (
          this.applyCommasToNumber &&
          (this.customInputType === 'naturalNumber' ||
            this.customInputType === 'wholeNumber' ||
            this.customInputType === 'integer' ||
            this.customInputType === 'monetary' ||
            this.customInputType === 'negativeMonetary' ||
            this.customInputType === 'positiveDecimal')
        ) {
          let subStrs = this.inputAbstractControl.value.split('.')
          if (subStrs.length === 2) {
            let wholePart = subStrs[0]
            let decimalPart = subStrs[1]
            wholePart = wholePart.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            let value = `${wholePart}.${decimalPart}`
            this.inputAbstractControl.patchValue(value)
            this.inputAbstractControl.updateValueAndValidity()
          } else {
            //let value = this.inputAbstractControl.value
            let value = this.parentFormGroup
              .get(this.inputFormControlName)
              ?.value?.toString()
              ?.replaceAll(',', '')
            value = value ? value.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : value
            this.parentFormGroup
              .get(this.inputFormControlName)
              ?.patchValue(value)
            this.parentFormGroup
              .get(this.inputFormControlName)
              ?.updateValueAndValidity()
          }
        }

        if (this._prohibitedCharactersRegex) {
          event.target.value = event.target.value.replace(
            this._prohibitedCharactersRegex,
            '',
          )
          this.inputAbstractControl.patchValue(event.target.value)
          this.inputAbstractControl.updateValueAndValidity()
        }
      }
    } else {
      if (
        this.applyCommasToNumber &&
        (this.customInputType === 'naturalNumber' ||
          this.customInputType === 'wholeNumber' ||
          this.customInputType === 'integer' ||
          this.customInputType === 'monetary' ||
          this.customInputType === 'negativeMonetary' ||
          this.customInputType === 'positiveDecimal')
      ) {
        let subStrs = this.inputAbstractControl.value.toString().split('.')
        if (subStrs.length === 2) {
          let wholePart = subStrs[0]
          let decimalPart = subStrs[1]
          wholePart = wholePart.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          let value = `${wholePart}.${decimalPart}`
          this.inputAbstractControl.patchValue(value)
          this.inputAbstractControl.updateValueAndValidity()
        } else {
          let value = this.parentFormGroup
            .get(this.inputFormControlName)
            ?.value?.toString()
            ?.replaceAll(',', '')
          value = value ? value.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : value
          this.parentFormGroup.get(this.inputFormControlName)?.patchValue(value)
          this.parentFormGroup
            .get(this.inputFormControlName)
            ?.updateValueAndValidity()
        }
      }
    }
  }

  clearInput() {
    this.inputAbstractControl.patchValue('')
    this.inputAbstractControl.updateValueAndValidity()
  }

  onBlur() {
    if (
      this.customInputType === 'monetary' ||
      this.customInputType === 'negativeMonetary'
    ) {
      const pattern = /\.[0-9]{0,1}$/ // has decimal and 0 or 1 number following it
      if (
        this.inputAbstractControl.value &&
        pattern.test(this.inputAbstractControl.value)
      ) {
        let subStrs = this.inputAbstractControl.value.split('.')
        if (subStrs.length === 2) {
          let dollarValue = subStrs[0]
          let centValue = subStrs[1]
          if (dollarValue === '') {
            dollarValue = '0'
          }
          if (centValue) {
            // has 1 number following decimal
            centValue = centValue + '0'
          } else {
            // has 0 numbers following decimal
            centValue = '00'
          }
          let value = `${dollarValue}.${centValue}`
          this.inputAbstractControl.patchValue(value)
          this.inputAbstractControl.updateValueAndValidity()
        }
      }
      if (
        this.customInputType === 'negativeMonetary' &&
        this.inputAbstractControl.value === '-'
      ) {
        this.inputAbstractControl.patchValue('')
        this.inputAbstractControl.updateValueAndValidity()
      }
    } else if (this.customInputType === 'positiveDecimal') {
      const pattern = /\.[0-9]*$/ // has decimal and 0 or more following it
      if (
        this.inputAbstractControl.value &&
        pattern.test(this.inputAbstractControl.value)
      ) {
        let subStrs = this.inputAbstractControl.value.split('.')
        if (subStrs.length === 2) {
          let wholePart = subStrs[0]
          let decimalPart = subStrs[1]
          if (wholePart === '') {
            wholePart = '0'
          }
          if (decimalPart === '') {
            decimalPart = '0'
          }
          let value = `${wholePart}.${decimalPart}`
          this.inputAbstractControl.patchValue(value)
          this.inputAbstractControl.updateValueAndValidity()
        }
      }
    }
  }

  private _setUpForm() {
    if (this.handleAddRemoveControl) {
      this.parentFormGroup.addControl(
        this.inputFormControlName,
        this.inputAbstractControl,
      )
    }
  }

  private _removeControl() {
    if (this.handleAddRemoveControl) {
      this.parentFormGroup.removeControl(this.inputFormControlName)
    }
  }

  private _handleStatusChange() {
    this._errorsSubscription = this.inputAbstractControl.statusChanges.subscribe(
      (status) => {
        this._setHighestPriorityCustomErrorMessage()
      },
    )
  }

  private _setHighestPriorityCustomErrorMessage() {
    // TODO!!! - FIX ERROR HANDLEING
    // let errors = this.inputAbstractControl.errors
    // if (errors) {
    //   let errorKeys = Object.keys(errors)
    //   let customErrorKeys = errorKeys.filter(
    //     (errorKey) =>
    //       errorKey !== 'required' &&
    //       errorKey !== 'min' &&
    //       errorKey !== 'max' &&
    //       errorKey !== 'minlength' &&
    //       errorKey !== 'maxlength' &&
    //       errorKey !== 'email' &&
    //       errorKey !== 'pattern',
    //   )
    //   if (customErrorKeys.length > 0) {
    //     let highestPriorityCustomErrorKey = customErrorKeys[0]
    //     let highestPriorityCustomErrorIndex: number | null = null
    //     customErrorKeys.forEach((customErrorKey) => {
    //       if (
    //         !isNaN(errors?[customErrorKey].priorityIndex) &&
    //         errors?[customErrorKey].priorityIndex !== null &&
    //         errors?[customErrorKey].priorityIndex !== undefined
    //       ) {
    //         if (
    //           highestPriorityCustomErrorIndex === null ||
    //           errors[customErrorKey].priorityIndex <
    //             highestPriorityCustomErrorIndex
    //         ) {
    //           highestPriorityCustomErrorIndex =
    //             errors[customErrorKey].priorityIndex
    //           highestPriorityCustomErrorKey = customErrorKey
    //         }
    //       }
    //     })
    //     this.highestPriorityCustomErrorMessage =
    //       errors[highestPriorityCustomErrorKey].message || 'Error'
    //   } else {
    //     this.highestPriorityCustomErrorMessage = ''
    //   }
    // } else {
    //   this.highestPriorityCustomErrorMessage = ''
    // }
  }

  private _convertTenDigitsToPhoneNumberFormat(phoneNumberStr: string): string {
    const openingParenthesesMatchPattern = /^\(/
    if (
      phoneNumberStr.length > 0 &&
      !openingParenthesesMatchPattern.test(phoneNumberStr)
    ) {
      phoneNumberStr = this._insertStringIntoString(phoneNumberStr, '(', 0)
    }
    const closingParenthesesMatchPattern = /^\([0-9]{3}\)/
    if (
      phoneNumberStr.length >= 4 &&
      !closingParenthesesMatchPattern.test(phoneNumberStr)
    ) {
      phoneNumberStr = this._insertStringIntoString(phoneNumberStr, ')', 4)
    }
    const emptySpaceMatchPattern = /^\([0-9]{3}\)\s/
    if (
      phoneNumberStr.length >= 5 &&
      !emptySpaceMatchPattern.test(phoneNumberStr)
    ) {
      phoneNumberStr = this._insertStringIntoString(phoneNumberStr, ' ', 5)
    }
    const dashSpaceMatchPattern = /^\([0-9]{3}\)\s[0-9]{3}\-/
    if (
      phoneNumberStr.length >= 9 &&
      !dashSpaceMatchPattern.test(phoneNumberStr)
    ) {
      phoneNumberStr = this._insertStringIntoString(phoneNumberStr, '-', 9)
    }
    return phoneNumberStr
  }

  private _insertStringIntoString(
    myString: string,
    stringToInsert: string,
    index: number,
  ) {
    return myString.substr(0, index) + stringToInsert + myString.substr(index)
  }
}
