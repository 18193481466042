<div class="input-wrapper" [formGroup]="parentFormGroup" [ngClass]="{ 'tql-style': useTqlStyling }"
  [ngStyle]="{ width: getWidth(inputWidthCategory) }">
  <mat-form-field [attr.id]="formFieldId" appearance="fill">
    <label *ngIf="labelText && useTqlStyling">{{ labelText }} <span *ngIf="isRequired" class="asterisk"> *</span>
    </label>
    <label *ngIf="disableAddressAutofill" class="filler-label">filler-label</label>
    <div class="tooltip-touch-action" [ngClass]="{
        'symbol-input': symbol,
        'symbol-left': symbol === 'dollar',
        'symbol-right': symbol === 'percent'
      }" [matTooltip]="
        showTooltipOnInput && inputAbstractControl.value
          ? tooltipTextOnInput || inputAbstractControl.value
          : null
      ">
      <span class="symbol" [ngClass]="{
          'accommodate-clear': includeClearButton && inputAbstractControl.value
        }">
        {{ symbol === "dollar" ? "$" : ""
        }}{{ symbol === "percent" ? "%" : "" }}
      </span>
      <input matInput [type]="inputType" [placeholder]="useTqlStyling ? placeholderText : labelText"
        [readonly]="isReadOnly" [formControlName]="inputFormControlName" [maxlength]="maxLengthToApply()"
        [min]="minValue" [max]="maxValue" [autocomplete]="disableAutofill ? 'off' : 'on'"
        [ngClass]="{ 'is-invalid': showErrors && inputAbstractControl.invalid }" [tabindex]="tabIndex"
        (input)="checkInput($event)" (blur)="onBlur()" [attr.testing-id]="testingId" />
    </div>
    <button *ngIf="!isReadOnly && includeClearButton && inputAbstractControl.value" matSuffix mat-button mat-icon-button
      type="button" (click)="clearInput()" [tabindex]="tabIndex">
      <span>X</span>
    </button>
    <mat-hint *ngIf="
        useTqlStyling && showErrors && inputAbstractControl.hasError('required')
      ">
      {{ requiredErrorMessage }}
    </mat-hint>
    <mat-hint *ngIf="
        useTqlStyling && showErrors && inputAbstractControl.hasError('min')
      ">
      {{ minValueErrorMessage }}
    </mat-hint>
    <mat-hint *ngIf="
        useTqlStyling && showErrors && inputAbstractControl.hasError('max')
      ">
      {{ maxValueErrorMessage }}
    </mat-hint>
    <mat-hint *ngIf="
        useTqlStyling &&
        showErrors &&
        inputAbstractControl.hasError('minlength')
      ">
      {{ minLengthErrorMessage }}
    </mat-hint>
    <mat-hint *ngIf="
        useTqlStyling &&
        showErrors &&
        inputAbstractControl.hasError('maxlength')
      ">
      {{ maxLengthErrorMessage }}
    </mat-hint>
    <mat-hint *ngIf="
        useTqlStyling && showErrors && inputAbstractControl.hasError('email')
      ">
      {{ emailErrorMessage }}
    </mat-hint>
    <mat-hint *ngIf="
        useTqlStyling && showErrors && inputAbstractControl.hasError('pattern')
      ">
      {{ patternErrorMessage }}
    </mat-hint>
    <ng-container *ngIf="inputAbstractControl.errors && showSingleCustomValidation">
      <mat-error *ngIf="useTqlStyling && showErrors && highestPriorityCustomErrorMessage">
        {{ highestPriorityCustomErrorMessage }}
      </mat-error>
    </ng-container>
    <ng-container *ngIf="inputAbstractControl.errors && !showSingleCustomValidation">
      <ng-container *ngFor="let errorKey of objectKeys(inputAbstractControl.errors)">
        <mat-error *ngIf="
            useTqlStyling &&
            showErrors &&
            inputAbstractControl.errors[errorKey].message
          ">
          {{ inputAbstractControl.errors[errorKey].message }}
        </mat-error>
      </ng-container>
    </ng-container>
  </mat-form-field>
</div>