import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { NgModule } from '@angular/core'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { MsalRedirectComponent } from '@azure/msal-angular'
import { HeaderComponent } from './shared/header/header.component'
import { FooterComponent } from './shared/footer/footer.component'
import { BreadcrumbComponent } from './shared/breadcrumb/breadcrumb.component'
import { HttpClient, HttpClientModule } from '@angular/common/http'
import { ProgressIndicatorComponent } from './shared/progress-indicator/progress-indicator.component'
import { PieSliceComponent } from './shared/progress-indicator/pie-slice/pie-slice.component'
import { CoreModule } from './shared/core/core.module'
import { MaterialsModule } from './shared/material/materials.module'
import { TabDirective } from './shared/directives/tab.directive'

const cFrameSet = [
  HeaderComponent,
  FooterComponent,
  BreadcrumbComponent,
  ProgressIndicatorComponent,
  PieSliceComponent,
]
@NgModule({
  declarations: [AppComponent, ...cFrameSet],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    CoreModule,
    MaterialsModule,
  ],
  providers: [HttpClient],
  bootstrap: [AppComponent, ...cFrameSet, MsalRedirectComponent],
})
export class AppModule {}
