import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'
import { environment } from 'src/environments/environment'
import { Rate, RateServiceResponsePayload } from '../models/rate'

@Injectable({
  providedIn: 'root',
})
export class RateService {
  apiUrl: string = ''
  constructor(private http: HttpClient) {
    this.apiUrl = `${environment.quickQuoteApiServerUrl}/select-rate`
  }

  getRates(quoteId: string): Observable<RateServiceResponsePayload> {
    return this.http.get<RateServiceResponsePayload>(
      `${this.apiUrl}/rates/${quoteId}`,
    )
  }
}
