import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { BehaviorSubject, Observable } from 'rxjs'
import { ApplicationState } from '../models/application-state'
import { environment } from 'src/environments/environment'
import { PreQuote, Quote } from '../models/quote'

@Injectable({
  providedIn: 'root',
})
export class AppStateService {
  private _appState$: BehaviorSubject<ApplicationState>

  constructor(private http: HttpClient) {
    this._appState$ = new BehaviorSubject<ApplicationState>(
      new ApplicationState(),
    )
  }

  get appState$(): Observable<ApplicationState> {
    return this._appState$.asObservable()
  }

  setAppState(appState: ApplicationState) {
    if (!environment.isProduction && !environment.isStage) {
      console.log('inside setAppState with: ', appState)
    }
    this._appState$.next(appState)
  }

  setCustomer(customer: any) {
    if (!environment.isProduction && !environment.isStage) {
      console.log('inside setCustomer with: ', customer)
    }
    this._appState$.value.CUSTOMER.next(customer)
  }

  setQuote(quote: Quote | PreQuote) {
    if (!environment.isProduction && !environment.isStage) {
      console.log('inside setQuote with: ', quote)
    }
    this._appState$.value.QUOTE.next(quote)
  }

  setRates(rates: any) {
    if (!environment.isProduction && !environment.isStage) {
      console.log('inside setRates with: ', rates)
    }
    this._appState$.value.RATES.next(rates)
  }

  setStops(stops: any) {
    if (!environment.isProduction && !environment.isStage) {
      console.log('inside setStops with: ', stops)
    }
    this._appState$.value.STOPS.next(stops)
  }

  setShipmentDetails(shipmentDetails: any) {
    if (!environment.isProduction && !environment.isStage) {
      console.log('inside setShipmentDetails with: ', shipmentDetails)
    }
    this._appState$.value.SHIPMENT_DETAILS.next(shipmentDetails)
  }

  setTrayStatus(trayStatus: any) {
    if (!environment.isProduction && !environment.isStage) {
      console.log('inside setTrayStatus with: ', trayStatus)
    }
    this._appState$.value.TRAYSTATUS.next(trayStatus)
  }
}
