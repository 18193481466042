<div class="component-container">
  <div class="checkbox-wrapper" [formGroup]="parentFormGroup" [ngClass]="{ 'tql-style': useTqlStyling }">
    <mat-checkbox [formControlName]="checkboxFormControlName" [color]="colorTheme" labelPosition="before"
      [ngClass]="{ 'is-invalid': showErrors && checkboxAbstractControl?.invalid }" [tabIndex]="tabIndex"
      [attr.testing-id]="testingId">
      {{ checkboxLabel }}<span *ngIf="isRequired" class="asterisk"> *</span>
    </mat-checkbox>
  </div>
  <label for="mat-mdc-checkbox-{{idxData}}-input" class="mdc-label extra-label"
    *ngIf="useExtraLabel">{{extraLabel}}</label>
</div>