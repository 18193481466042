import {
  LogLevel,
  IPublicClientApplication,
  PublicClientApplication,
  BrowserCacheLocation,
  InteractionType,
  ILoggerCallback,
} from '@azure/msal-browser'
import {
  MsalInterceptorConfiguration,
  MsalGuardConfiguration,
  MsalService,
} from '@azure/msal-angular'

import { environment } from 'src/environments/environment'

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1

export function MSALInstanceFactory(
): IPublicClientApplication {
  return getPublicClientApplication()
}

export function MSALInterceptorConfigFactory(
): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>()

  protectedResourceMap.set(environment.quickQuoteApiServerUrl, environment.msalConfigAD.scopes)
  
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  }
}

export function MSALGuardConfigFactory(
): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: environment.msalConfigAD.scopes,
      authority: environment.msalConfigAD.authority,
    },
  }
}

export function getPublicClientApplication(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.msalConfigAD.clientId,
      authority: environment.msalConfigAD.authority,
      redirectUri: environment.msalConfigAD.redirectUri,
      navigateToLoginRequestUrl:
        environment.msalConfigAD.navigateToLoginRequestUrl,
      postLogoutRedirectUri: environment.msalConfigAD.postLogoutRedirectUri,
      knownAuthorities: environment.msalConfigAD.knownAuthorities,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
    // Turn this on in Dev for debugging
    system: {
      loggerOptions: {
        loggerCallback(
          logLevel: LogLevel,
          message: string,
          containsPii: boolean,
        ): void {
          if (!environment.msalConfigAD.loggingEnabled) {
            return
          }

          switch (logLevel) {
            case LogLevel.Error:
              console.error(message)
              break
            case LogLevel.Warning:
              console.warn(message)
              break
            case LogLevel.Info:
              console.info(message)
              break
            case LogLevel.Verbose:
              console.debug(message)
              break
          }
        },
        logLevel: environment.msalConfigAD.logLevel,
        piiLoggingEnabled: false,
      },
    },
  })
}
