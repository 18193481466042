import { Component, Input, OnDestroy } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { blueAndGrayAnimation } from '../../animations/angular/blue-and-gray.animation'
import { StepsInUserJourney } from 'src/app/app-routing.module'

@Component({
  selector: 'tql-pie-slice',
  templateUrl: './pie-slice.component.html',
  styleUrls: ['./pie-slice.component.scss'],
  animations: [blueAndGrayAnimation],
})
export class PieSliceComponent implements OnDestroy {
  @Input()
  myRole!: string
  @Input()
  myColor!: string

  hasHappened = false
  myRouteSub: any

  constructor(private router: Router) {
    this.myRouteSub = this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        let targetRoute = val.url.split('?')[0]
        switch (targetRoute.substring(1)) {
          case StepsInUserJourney.SelectQuoteType:
            if (this.myRole == StepsInUserJourney.SelectQuoteType) {
              this.hasHappened = true
            } else {
              this.hasHappened = false
            }
            break
          case StepsInUserJourney.QuoteDetails:
            if (
              this.myRole == StepsInUserJourney.SelectQuoteType ||
              this.myRole == StepsInUserJourney.QuoteDetails
            ) {
              this.hasHappened = true
            } else {
              this.hasHappened = false
            }
            break
          case StepsInUserJourney.SelectRate:
            this.hasHappened = true
            break
          default:
            break
        }
      }
    })
  }

  ngOnDestroy(): void {
    this.myRouteSub.unsubscribe()
  }
}
