import { Component, OnDestroy, OnInit } from '@angular/core'
import { Router, NavigationEnd } from '@angular/router'
import { StepsInUserJourney } from 'src/app/app-routing.module'
import { fadeOutThenInAnimation } from '../animations/angular/fade.animation'

@Component({
  selector: 'tql-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  animations: [fadeOutThenInAnimation],
})
export class BreadcrumbComponent implements OnInit, OnDestroy {
  isSelectQuoteTypePage = false
  isQuoteDetailsPage = false
  isSelectRatePage = false
  isTenderPage = false
  myRouteSub: any
  constructor(private router: Router) {}

  ngOnDestroy(): void {
    this.myRouteSub.unsubscribe()
  }

  ngOnInit() {
    this.myRouteSub = this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        let targetRoute = val.url.split('?')[0]
        switch (targetRoute.substring(1)) {
          case StepsInUserJourney.SelectQuoteType:
            this.isQuoteDetailsPage = false
            this.isSelectRatePage = false
            this.isSelectQuoteTypePage = true
            this.isTenderPage = false
            break
          case StepsInUserJourney.QuoteDetails:
            this.isQuoteDetailsPage = true
            this.isSelectRatePage = false
            this.isSelectQuoteTypePage = false
            this.isTenderPage = false
            break
          case StepsInUserJourney.SelectRate:
            this.isQuoteDetailsPage = false
            this.isSelectRatePage = true
            this.isSelectQuoteTypePage = false
            this.isTenderPage = false
            break
          default:
            this.isQuoteDetailsPage = false
            this.isSelectRatePage = false
            this.isSelectQuoteTypePage = false
            this.isTenderPage = false
            break
        }
      }
    })
  }

  getActiveUrlWidth() {
    let activeUrl = this.router.url.split('?')[0]
    switch (activeUrl.substring(1)) {
      case StepsInUserJourney.SelectQuoteType:
        return '8rem'
      case StepsInUserJourney.QuoteDetails:
        return '9rem'
      case StepsInUserJourney.SelectRate:
        return '9rem'
      default:
        return '8rem'
    }
  }

  getActiveUrlPosition() {
    let activeUrl = this.router.url.split('?')[0]
    switch (activeUrl.substring(1)) {
      case StepsInUserJourney.SelectQuoteType:
        return '3%'
      case StepsInUserJourney.QuoteDetails:
        return '18.5%'
      case StepsInUserJourney.SelectRate:
        return '34%'
      default:
        return '3%'
    }
  }
}
