import { Component, OnDestroy, OnInit } from '@angular/core'

import { NavigationEnd, Router } from '@angular/router'
import { StepsInUserJourney } from 'src/app/app-routing.module'
import { fadeOutThenInAnimation } from '../animations/angular/fade.animation'
import { rollRotationAnimation } from '../animations/angular/roll-rotation.animation'

@Component({
  selector: 'tql-progress-indicator',
  templateUrl: './progress-indicator.component.html',
  styleUrls: ['./progress-indicator.component.scss'],
  animations: [rollRotationAnimation, fadeOutThenInAnimation],
})
export class ProgressIndicatorComponent implements OnInit, OnDestroy {
  rollDirectionCommand: string | undefined
  navigationEventInProgress: boolean = false
  activeStep: number = 1
  myRouteSub: any

  constructor(private router: Router) {
    this.navigationEventInProgress = true
    this.myRouteSub = this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.setAnimation()
        let targetRoute = val.url.split('?')[0]
        switch (targetRoute.substring(1)) {
          case StepsInUserJourney.SelectQuoteType:
            this.rollDirectionCommand = 'NoRotation'
            setTimeout(() => {
              this.activeStep = 1
            }, 500)
            break
          case StepsInUserJourney.QuoteDetails:
            this.rollDirectionCommand = 'OneClickRight'
            setTimeout(() => {
              this.activeStep = 2
            }, 500)
            break
          case StepsInUserJourney.SelectRate:
            this.rollDirectionCommand = 'TwoClickRight'
            setTimeout(() => {
              this.activeStep = 3
            }, 500)
            break
          default:
            break
        }
      }
    })
  }

  ngOnDestroy(): void {
    this.myRouteSub.unsubscribe()
  }

  ngOnInit() {}

  setAnimation() {
    this.navigationEventInProgress = true
    setTimeout(() => {
      this.navigationEventInProgress = false
    }, 500)
  }
}
