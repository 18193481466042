import { Injectable } from '@angular/core'
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
} from '@angular/common/http'
import { Observable } from 'rxjs'
import { SpinnerService } from '../services/spinner.service'

@Injectable()
export class SpinnerInterceptor implements HttpInterceptor {
  private requests: HttpRequest<any>[] = []
  private nonSpinnerRequests: string[] = []

  constructor(private loaderService: SpinnerService) {
    this.initNonSpinnerRequests()
  }

  removeRequest(req: HttpRequest<any>) {
    const i = this.requests.indexOf(req)
    if (i >= 0) {
      this.requests.splice(i, 1)
    }
    let showSpinner =
      this.shouldShowSpinner(req.url) &&
      this.requests.length > 0 &&
      !this.isAllOngoingRequestsNoSpinner()
    this.loaderService.isLoading.next(showSpinner)
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    this.requests.push(req)
    //excludes the loader service to remove spinning wheel
    if (this.shouldShowSpinner(req.url)) {
      this.loaderService.isLoading.next(true)
    }
    return new Observable((observer) => {
      const subscription = next.handle(req).subscribe({
        next: (event) => {
          if (event instanceof HttpResponse) {
            this.removeRequest(req)
            observer.next(event)
          }
        },
        error: (err) => {
          this.removeRequest(req)
          observer.error(err)
        },
        complete: () => {
          this.removeRequest(req)
          observer.complete()
        },
      })
      // teardown logic in case of cancelled requests
      return () => {
        this.removeRequest(req)
        subscription.unsubscribe()
      }
    })
  }

  private shouldShowSpinner(url: string): boolean {
    let showSpinner = true
    for (let i in this.nonSpinnerRequests) {
      if (url.includes(this.nonSpinnerRequests[i])) {
        showSpinner = false
        break
      }
    }
    return showSpinner
  }

  private initNonSpinnerRequests() {
    this.nonSpinnerRequests.push('quickquote/healthcheck')
  }

  private isAllOngoingRequestsNoSpinner() {
    this.requests.forEach((request) => {
      this.shouldShowSpinner(request.url)
        ? () => {
            return false
          }
        : () => {}
    })
    return true
  }
}
