import { formatDate } from '@angular/common';
import { Commodity } from './commodity'
import { Customer } from './customer-option.interface';
import { Stop } from './stop'

export enum QuoteSource {
  QUICKQUOTE = 'QUICKQUOTE',
  PREQUOTE = 'PREQUOTE',
}

export interface IQuote {
  id: string
  stops: Array<Stop>
  commodities: Array<Commodity>
}

export class Quote implements IQuote{
  customer!: Customer
  id: string = ''
  stops!: Array<Stop>
  commodities!: Array<Commodity>
}

export class PreQuote implements IQuote{
  prequoteId: string | undefined
  customer!: Customer
  id: string = ''
  stops!: Array<Stop>
  commodities!: Array<Commodity>
}

export class QuoteCreateCM implements IQuote{
  prequoteId?: string
  customerId!: number
  id: string = ''
  stops!: Array<Stop>
  commodities!: Array<Commodity>
  constructor(formValues: any, id?: string) {
    this.id = id ? id : ''
    this.customerId = formValues.customerSearchAutocomplete.id
    this.stops = [
      {
        locationId: formValues.pickLocationSearchAutocomplete.id,
        scheduledDate: formatDate(new Date(formValues.pickupDate).toLocaleDateString(),'yyyy-MM-dd', 'en-US'),
        type: 'PICK',
      },
      {
        locationId: formValues.dropLocationSearchAutocomplete.id,
        scheduledDate: formValues.dropLocationPickupDate,
        type: 'DROP',
      },
    ]
    this.commodities = []
    formValues.commodities.map((commodity: any) => {
      this.commodities.push({
        weight: commodity.weightInput
          ? parseInt(commodity.weightInput.replaceAll(',', ''))
          : null,
        quantity: parseInt(commodity.quantityInput),
        length: parseInt(commodity.lengthInput),
        width: parseInt(commodity.widthInput),
        height: parseInt(commodity.heightInput),
        freightClass: commodity.freightInput,
        isHazmat: commodity.quoteDetailsIsHazmat,
        isStackable: commodity.quoteDetailsIsStackable,
      })
    })
  }
}
