import { NgClass, NgFor, NgIf } from '@angular/common'
import { Component, Inject, Input } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog'
import { IDialogData } from '../../models/dialog-data.interface'
import { DialogModalsService } from '../../services/dialog-modals.service'
import { MatIconModule } from '@angular/material/icon'

@Component({
  imports: [MatDialogModule, MatIconModule, NgFor, NgIf, NgClass],
  selector: 'tql-error-handling',
  templateUrl: './error-handling.component.html',
  standalone: true,
  styleUrls: ['./error-handling.component.scss'],
})
export class ErrorHandlingComponent {
  @Input() hasConfirmButton: boolean = true
  @Input() hasCancelButton: boolean = true
  @Input() showHeader: boolean = true

  constructor(
    private dialogModalsService: DialogModalsService,
    @Inject(MAT_DIALOG_DATA)
    public data: IDialogData,
  ) {
    console.log(data)
    this.hasCancelButton = data.hasCancelButton
    this.hasConfirmButton = data.hasConfirmButton
    this.showHeader = data.showHeader
  }

  confirm() {
    this.dialogModalsService.close()
  }

  cancel() {
    this.dialogModalsService.close()
  }

  closeModal() {
    this.dialogModalsService.close()
  }
}
