import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { MsalGuard, MsalRedirectComponent } from '@azure/msal-angular'
import { BrowserUtils } from '@azure/msal-browser'
import { QuoteResolver } from './resolvers/quote.resolver'
import { RateResolver } from './resolvers/rate.resolver'

const routes: Routes = [
  {
    path: '',
    redirectTo: 'select-quote-type',
    pathMatch: 'full',
  },
  {
    path: 'select-quote-type',
    loadChildren: () =>
      import('./routes/select-quote-type/select-quote-type.module').then(
        (m) => m.SelectQuoteTypeModule,
      ),
    canActivate: [MsalGuard],
  },
  {
    path: 'quote-details',
    loadChildren: () =>
      import('./routes/quote-details/quote-details.module').then(
        (m) => m.QuoteDetailsModule,
      ),
    canActivate: [MsalGuard],
  },
  {
    path: 'select-rate',
    loadChildren: () =>
      import('./routes/select-rate/select-rate.module').then(
        (m) => m.SelectRateModule,
      ),
    resolve: { quote: QuoteResolver, rates: RateResolver },
    canActivate: [MsalGuard],
  },
  {
    path: 'silentcallback',
    component: MsalRedirectComponent,
  },
  {
    path: 'authcallback',
    component: MsalRedirectComponent,
  },
  {
    path: '**',
    redirectTo: 'select-quote-type',
  },
]
export const enum StepsInUserJourney {
  SelectQuoteType = 'select-quote-type',
  QuoteDetails = 'quote-details',
  SelectRate = 'select-rate',
}
const isIframe = window !== window.parent && !window.opener
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      // Don't perform initial navigation in iframes or popups
      initialNavigation:
        !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup()
          ? 'enabledNonBlocking'
          : 'disabled', // Set to enabledBlocking to use Angular Universal
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
