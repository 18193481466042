import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core'
import {
  Router,
  NavigationStart,
  NavigationCancel,
  NavigationError,
  NavigationEnd,
  ActivatedRoute,
} from '@angular/router'
import { MsalBroadcastService, MsalService } from '@azure/msal-angular'
import { EventMessage, InteractionStatus, EventType, RedirectRequest, SilentRequest, AuthenticationResult } from '@azure/msal-browser'
import { Subject, filter, takeUntil } from 'rxjs'
import { AuthService } from './shared/core/authentication/auth.service'
import { SpinnerService } from './shared/core/services/spinner.service'
import { environment } from 'src/environments/environment'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy, OnInit {
  asyncCallInProgress: boolean = false
  navigationInProgress: boolean = false
  isIframe = false
  isAuthenticated: boolean = false
  private _unsubscribe$ = new Subject()
  constructor(
    private spinnerService: SpinnerService,
    private router: Router,
    private msalService: MsalService,
    private authService: AuthService,
    private msalBroadcastService: MsalBroadcastService,
    private cdRef: ChangeDetectorRef,
  ) {
    this.router.events
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe((event) => {
        if (event instanceof NavigationStart) {
          this.navigationInProgress = true
        }
        if (
          event instanceof NavigationCancel ||
          event instanceof NavigationError
        ) {
          this.navigationInProgress = false
        }
        if (event instanceof NavigationEnd) {
          this.navigationInProgress = false
          let route: ActivatedRoute = this.router.routerState.root
          while (route!.firstChild) {
            route = route.firstChild
          }
        }
      })
  }

  ngOnInit() {
    this.isIframe = window !== window.parent && !window.opener

    this._setupMsalService()

    this.spinnerService.isLoading
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe((data) => {
        this.asyncCallInProgress = data
        this.cdRef.detectChanges()
      })

    this.spinnerService.isLoading.next(true);
  }

  ngOnDestroy() {
    this._unsubscribe$.next(null)
    this._unsubscribe$.complete()
    this.msalService.logout()
    this.isAuthenticated = false
  }

  private _setupMsalService() {
    this.msalBroadcastService.msalSubject$
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe((msg: EventMessage) => {
        if (
          msg.eventType === EventType.LOGIN_SUCCESS ||
          msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
        ) {
          this.authService.setIsAuthenticated(true)
        }
    });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None,
        ),
        takeUntil(this._unsubscribe$),
      )
      .subscribe((status: InteractionStatus) => {
        this.msalService.acquireTokenSilent(this.getSilentRequest())
        .subscribe({
          next: (response: AuthenticationResult) => 
            {
              this.msalService.instance.setActiveAccount(response.account);
              this.isAuthenticated = true;
              this.spinnerService.isLoading.next(false);
            },
          error: (e) => this.login(),
        });
    });
  }

  private login(){
    this.msalService.instance.loginRedirect(this.getRedirectRequest());
  }
  
  private getRedirectRequest(): RedirectRequest{
    return {
        scopes: environment.msalConfigAD.scopes,
        redirectUri: environment.msalConfigAD.redirectUri,
        authority: environment.msalConfigAD.authority,
    }
  }

  private getSilentRequest(): SilentRequest{
    return {
        scopes: environment.msalConfigAD.scopes,
        account: this.msalService.instance.getAllAccounts()[0],
        authority: environment.msalConfigAD.authority,
        forceRefresh: true
    }
  }
}
