<div [@rollRotationAnimation]="rollDirectionCommand" class="animation-container">
    <div class="progress-indicator">
        <tql-pie-slice class="select-quote-type" myRole="select-quote-type"></tql-pie-slice>
        <tql-pie-slice class="select-rate" myRole="select-rate"></tql-pie-slice>
        <tql-pie-slice class="quote-details" myRole="quote-details"></tql-pie-slice>
    </div>
</div>
<div class="progress-steps">
    Step <span [@fadeOutThenInAnimation]="navigationEventInProgress ? 'NotShown': 'Shown'"
        class="">{{activeStep}}</span> of 3
</div>