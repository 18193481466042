import {
  Component,
  OnInit,
  Input,
  Output,
  ViewChild,
  AfterViewInit,
  OnDestroy,
  EventEmitter,
} from '@angular/core'
import { UntypedFormGroup, AbstractControl } from '@angular/forms'
import {
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger,
} from '@angular/material/autocomplete'
import { Observable, of, Subscription } from 'rxjs'

import { DomSanitizer } from '@angular/platform-browser'
import { MatIconRegistry } from '@angular/material/icon'
import { ISelectOption } from '../../models/select-option.interface'

const ERROR_ICON = `<svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="7.5" cy="7.5" r="7.5" fill="#FF4D4F"/>
<path d="M7.50017 11.055C7.87403 11.055 8.1771 10.752 8.1771 10.3781C8.1771 10.0043 7.87403 9.70119 7.50017 9.70119C7.12631 9.70119 6.82324 10.0043 6.82324 10.3781C6.82324 10.752 7.12631 11.055 7.50017 11.055Z" fill="white"/>
<path d="M7.50017 8.34729C7.32064 8.34729 7.14846 8.27598 7.02151 8.14903C6.89456 8.02208 6.82324 7.8499 6.82324 7.67036V4.96264C6.82324 4.78311 6.89456 4.61093 7.02151 4.48398C7.14846 4.35703 7.32064 4.28571 7.50017 4.28571C7.67971 4.28571 7.85189 4.35703 7.97883 4.48398C8.10578 4.61093 8.1771 4.78311 8.1771 4.96264V7.67036C8.1771 7.8499 8.10578 8.02208 7.97883 8.14903C7.85189 8.27598 7.67971 8.34729 7.50017 8.34729Z" fill="white"/>
</svg>`

@Component({
  selector: 'tql-form-ele-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
})
export class AutocompleteComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('trigger') matAutocompleteTrigger!: MatAutocompleteTrigger

  @Output() optionSelected = new EventEmitter()
  @Output() optionCleared = new EventEmitter()
  @Output() tryAgain = new EventEmitter()

  @Input() formFieldId?: string
  @Input() labelText: string = '' // req
  @Input() placeholderText: string = ''
  @Input() optionsData: Observable<ISelectOption[]> = of([]) // req
  @Input() displayFn: any // req
  @Input() panelWidth: string | number = '256px'
  @Input() autoActiveFirstOption: boolean = true
  @Input() isDisabled: boolean = false
  @Input() isRequired: boolean = false // req
  @Input() showErrors: boolean = true // req
  @Input() tooltipName: string = ''
  @Input() tooltipText: string = ''
  @Input() tooltipPlacement:
    | 'top'
    | 'top-start'
    | 'top-end'
    | 'right'
    | 'right-start'
    | 'right-end'
    | 'bottom'
    | 'bottom-start'
    | 'bottom-end'
    | 'left'
    | 'left-start'
    | 'left-end'
    | 'auto'
    | 'auto-start'
    | 'auto-end' = 'right'
  @Input() isTooltipClickOpen: boolean = false
  @Input() isTooltipModalInXs: boolean = false
  @Input() includeClearButton: boolean = false
  @Input() autoOpenSelectionPanelOnClear: boolean = true
  @Input() showTooltipOnSelected: boolean = false
  @Input() showTooltipOnOptions: boolean = false
  @Input() optionTooltipTogglePropertyName: string = 'showTooltip'
  @Input() optionTooltipDisplayPropertyName: string = 'tooltipText'
  @Input() requiredErrorMessage: string = 'Required'
  @Input() useTqlStyling: boolean = true // req
  @Input() handleAddRemoveControl: boolean = true
  @Input() disableAutofill: boolean = true
  @Input() disableAddressAutofill: boolean = false
  @Input() tabIndex?: number

  @Input() parentFormGroup!: UntypedFormGroup // req
  @Input() autocompleteFormControlName!: string // req
  @Input() autocompleteAbstractControl!: AbstractControl // req

  @Input() testingId?: string

  @Input() inputWidthCategory?: string // xs, sm, md, lg // optional

  highestPriorityCustomErrorMessage: string = ''

  selectedOptionTooltipText: string = ''

  private _errorsSubscription: Subscription = Subscription.EMPTY

  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    iconRegistry.addSvgIconLiteral(
      'error_icon',
      sanitizer.bypassSecurityTrustHtml(ERROR_ICON),
    )
  }

  ngOnInit() {
    this._setUpForm()
  }

  ngAfterViewInit() {
    this._handleStatusChange()
  }

  ngOnDestroy() {
    this._removeControl()
    if (this._errorsSubscription) {
      this._errorsSubscription.unsubscribe()
    }
  }

  getWidth(widthCategory: any) {
    switch (widthCategory) {
      case 'xs':
        return '88px'
      case 'sm':
        return '176px'
      case 'md':
        return '256px'
      case 'lg':
        return '344px'
      case 'half-containment':
        return '46%'
      case 'third-containment':
        return '30%'
      case 'three-quarters-containment':
        return '68%'
      default:
        return '100%'
    }
  }

  onOptionSelected(event: MatAutocompleteSelectedEvent) {
    this.optionSelected.emit(event.option.value)
    if (this.showTooltipOnSelected && this.displayFn) {
      this.selectedOptionTooltipText = this.displayFn(event.option.value)
    }
  }

  clearInput() {
    this.autocompleteAbstractControl.patchValue('')
    this.autocompleteAbstractControl.updateValueAndValidity()
    this.optionCleared.emit()
    if (this.autoOpenSelectionPanelOnClear) {
      setTimeout(() => this.matAutocompleteTrigger?.openPanel())
    }
  }

  private _setUpForm() {
    if (this.handleAddRemoveControl) {
      this.parentFormGroup.addControl(
        this.autocompleteFormControlName,
        this.autocompleteAbstractControl,
      )
    }
  }

  private _removeControl() {
    if (this.handleAddRemoveControl) {
      this.parentFormGroup.removeControl(this.autocompleteFormControlName)
    }
  }

  private _handleStatusChange() {
    this._errorsSubscription = this.autocompleteAbstractControl.statusChanges.subscribe(
      (status) => {
        let errors = this.autocompleteAbstractControl.errors
        if (errors) {
          let errorKeys = Object.keys(errors)
          let customErrorKeys = errorKeys.filter(
            (errorKey) =>
              errorKey !== 'required' &&
              errorKey !== 'min' &&
              errorKey !== 'max' &&
              errorKey !== 'minlength' &&
              errorKey !== 'maxlength' &&
              errorKey !== 'email' &&
              errorKey !== 'pattern',
          )
          if (customErrorKeys.length > 0) {
            let highestPriorityCustomErrorKey = customErrorKeys[0]
            let highestPriorityCustomErrorIndex: number | null = null
            customErrorKeys.forEach((customErrorKey) => {
              //   if (
              //     !isNaN(errors[customErrorKey].priorityIndex) &&
              //     errors[customErrorKey].priorityIndex !== null &&
              //     errors[customErrorKey].priorityIndex !== undefined
              //   ) {
              //     if (
              //       highestPriorityCustomErrorIndex === null ||
              //       errors[customErrorKey].priorityIndex <
              //         highestPriorityCustomErrorIndex
              //     ) {
              //       highestPriorityCustomErrorIndex =
              //         errors[customErrorKey].priorityIndex
              //       highestPriorityCustomErrorKey = customErrorKey
              //     }
              //   }
            })
            this.highestPriorityCustomErrorMessage =
              errors[highestPriorityCustomErrorKey].message || 'Error'
          } else {
            this.highestPriorityCustomErrorMessage = ''
          }
        } else {
          this.highestPriorityCustomErrorMessage = ''
        }
      },
    )
  }
}
