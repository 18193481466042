/* "Barrel" of Http Interceptors */
import { HTTP_INTERCEPTORS } from '@angular/common/http'

import { MsalInterceptor } from '@azure/msal-angular'
import { SpinnerInterceptor } from './spinner-interceptor'
import { CacheBustingInterceptor } from './cache-busting-interceptor'
import { HttpErrorResponseInterceptor } from './http-error-response-interceptor'

/** Http interceptor providers in outside-in order */
export const HttpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: SpinnerInterceptor, multi: true },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: CacheBustingInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpErrorResponseInterceptor,
    multi: true,
  },
]
