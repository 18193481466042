import { Directive, AfterViewInit, OnDestroy, Optional } from '@angular/core'

import { NgControl } from '@angular/forms'
import { MatAutocompleteTrigger } from '@angular/material/autocomplete'

@Directive({ selector: '[tab-directive]' })
export class TabDirective implements AfterViewInit, OnDestroy {
  observable: any
  constructor(
    @Optional() private autoTrigger: MatAutocompleteTrigger,
    @Optional() private control: NgControl,
  ) {}
  ngAfterViewInit() {
    this.observable = this.autoTrigger.panelClosingActions.subscribe((x) => {
      if (!x) {
        if (this.autoTrigger.activeOption) {
          const value = this.autoTrigger.activeOption.value
          if (this.control)
            this.control.control?.setValue(value, {
              emit: false,
            })
          this.autoTrigger.writeValue(value)
        }
      } else {
        const value = x.source.value
        if (this.control)
          this.control.control?.setValue(value, {
            emit: false,
          })
        this.autoTrigger.writeValue(value)
      }
    })
  }
  ngOnDestroy() {
    this.observable.unsubscribe()
  }
}
