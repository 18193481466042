import { Injectable } from '@angular/core'

import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http'

import { finalize, tap, catchError } from 'rxjs/operators'
import { Observable, throwError } from 'rxjs'
import { environment } from 'src/environments/environment'
import { DialogModalsService } from '../../services/dialog-modals.service'

@Injectable()
export class HttpErrorResponseInterceptor implements HttpInterceptor {
  constructor(private dialogModalsService: DialogModalsService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const started = Date.now()
    let ok: string = ''
    let fail: string = ''
    let errors: string[] = []

    return next.handle(req).pipe(
      catchError((response: HttpErrorResponse) => {
        if (!environment.isProduction && !environment.isStage) {
          console.log(
            '%cERROR_RESPONSE:',
            'color: #e74c3c; background-color: #cccccc; font-size: 0.8rem; font-weight: bold;',
            response,
          )
        }
        switch (response.status) {
          case 400:
            errors.push(...this.parse400ErrorResponse(response.error))
            let errorMessages: any[] = []
            let errorKeys = Object.keys(response.error.errors)
            errorKeys.forEach((i: string | number) => {
              errorMessages.push(response.error.errors[i])
            })
            const dialogRef400 = this.dialogModalsService.openErrorMessage({
              panelClass: ['error-modal'],
              data: {
                showHeader: false,
                title: response.error.title,
                messages: errorMessages,
                hasConfirmButton: false,
                hasCancelButton: false,
              },
            })
            break
          default:
            fail = response.message
            errors: errors.push(
              'An error occurred, please contact Customer Service.',
            )
            const dialogRef500 = this.dialogModalsService.openErrorMessage({
              panelClass: ['error-modal'],
              data: {
                showHeader: false,
                title: '',
                messages: [
                  'An error occurred, please contact Customer Service.',
                ],
                hasConfirmButton: false,
                hasCancelButton: false,
              },
            })
            break
        }

        return throwError(errors)
      }),
      finalize(() => {
        if (fail || environment.logApiCalls) {
          let msg = fail ? fail : ok
          const elapsed = Date.now() - started
          const message = `${req.method} "${req.urlWithParams}" ${msg} in ${elapsed} ms.`
          console.log(message)
        }
      }),
    )
  }

  isNonEmptyArrayOfStrings(value: any): boolean {
    return (
      Array.isArray(value) &&
      !!value.length &&
      value.every((item: any) => typeof item === 'string')
    )
  }

  parse400ErrorResponse(error: any): string[] {
    let e: string[] = []

    if (error === null || error === undefined) {
      e.push('An error occurred, please contact Customer Service.')
    } else if (typeof error === 'string') {
      e.push(error)
    } else if (this.isNonEmptyArrayOfStrings(error)) {
      error.forEach((msg: string) => {
        e.push(msg)
      })
    } else if (this.isNonEmptyArrayOfStrings(error.value)) {
      error.value.forEach((msg: string) => {
        e.push(msg)
      })
    } else {
      try {
        if (Object.keys(error).length > 0) {
          let errorKeys = Object.keys(error.errors)
          errorKeys.forEach((i) => {
            e.push(error.errors[i])
          })
        } else {
          e.push(
            'An error occurred, please contact Customer Service.',
          )
        }
      } catch (error) {
        e.push(JSON.stringify(error))
      }
    }

    return e
  }
}
