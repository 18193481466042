import { trigger, transition, style, animate, state } from '@angular/animations'

export const rollRotationAnimation = trigger('rollRotationAnimation', [
  state('NoRotation', style({ transform: 'rotate(0deg)' })),
  state('OneClickRight', style({ transform: 'rotate(120deg)' })),
  state('TwoClickRight', style({ transform: 'rotate(240deg)' })),
  transition('* => NoRotation', [animate('.5s')]),
  transition('* => OneClickRight', [animate('.5s')]),
  transition('* => TwoClickRight', [animate('.5s')]),
])
